import { useApolloClient } from "@apollo/client"
import { createContext, useContext, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { LEGACY_SIGNIN, ONBOARDING_LOGOUT_URL } from "../../env_variables"
import { Challenge2faModal } from "./Challenge2faModal"
import { DeviceNotFoundModal } from "./DeviceNotFoundModals"

interface Challenge2faContextInterface {
  closeModal: () => void
  openModal: () => void
  openErrorModal: () => void
  isModalOpen: boolean
  timeRemaining: number
  setTimeRemaining: (time: number) => void
  setOnAfterClose: (cb: () => void) => void
  setPhone: (phone: string) => void
  setChallengeId: (challengeId: string) => void
}

const defaultFunction = () => {
  // should not happen
  console.error("ERROR_DEFAULT_CONTEXT_VALUE_USED")
}

const Challenge2faContext = createContext<Challenge2faContextInterface>({
  closeModal: defaultFunction,
  openModal: defaultFunction,
  openErrorModal: defaultFunction,
  isModalOpen: false,
  timeRemaining: 0,
  setTimeRemaining: defaultFunction,
  setOnAfterClose: defaultFunction,
  setPhone: defaultFunction,
  setChallengeId: defaultFunction,
})

export const useChallenge2faContext = () => useContext(Challenge2faContext)

interface Challenge2faProviderProps {
  children: JSX.Element
}

const Challenge2faProvider = ({ children }: Challenge2faProviderProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [timeRemaining, setTimeRemaining] = useState(0)
  const [phone, setPhone] = useState("")
  const [challengeId, setChallengeId] = useState("")
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)

  const client = useApolloClient()
  const navigate = useNavigate()

  function closeModal() {
    setIsModalOpen(false)
  }

  function openModal() {
    setIsModalOpen(true)
  }

  function openErrorModal() {
    setIsErrorModalOpen(true)
  }

  function closeErrorModal() {
    setIsErrorModalOpen(false)
  }

  const _onAfterClose = useRef(defaultFunction)

  return (
    <Challenge2faContext.Provider
      value={{
        closeModal,
        openModal,
        openErrorModal,
        isModalOpen,
        timeRemaining,
        setTimeRemaining,
        setPhone,
        setOnAfterClose: (onAfterClose) => {
          _onAfterClose.current = onAfterClose
        },
        setChallengeId,
      }}
    >
      {children}
      <Challenge2faModal
        is2faModalOpen={isModalOpen}
        close2faModal={() => {
          _onAfterClose.current()
          closeModal()
        }}
        phone={phone}
        challengeId={challengeId}
      />
      <DeviceNotFoundModal
        isOpen={isErrorModalOpen}
        close={() => {
          closeErrorModal()
          client.clearStore()
          localStorage.removeItem("token")

          if (LEGACY_SIGNIN) {
            navigate("/")
            return
          }
          window.location.href = ONBOARDING_LOGOUT_URL
        }}
      />
    </Challenge2faContext.Provider>
  )
}

export default Challenge2faProvider
